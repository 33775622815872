import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { graphql } from "gatsby";
import Img from 'gatsby-image';
import React from "react";
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import Logo from '../assets/images/keyring-planet-logo-white.svg';
import Footer from "../components/Footer.js";

export default function Listing(props) {
  const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: `700px`,
      margin: `auto`,
      padding: theme.spacing(1)
    },
    title: {
      padding: theme.spacing(6, 0, 4)
    },
    description: {
      padding: theme.spacing(6, 0, 4)
    },
    footer: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(6)
    },
    logo: {
      marginRight: theme.spacing(6),
      height: "10em",
      width: "10em"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Helmet defaultTitle="Keyring Planet" title={props.data.etsyListing.title} titleTemplate="%s - Keyring Planet">
        <meta charSet="utf-8" />
        <meta name="description" value={props.data.description} />
      </Helmet>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <a href="/" title="Keyring Planet">
            <Logo className={classes.logo}/>
          </a>
          <Typography component="h1" variant="h4" color="inherit" noWrap>
            Keyring Planet
          </Typography>
        </Toolbar>
      </AppBar>
      <Container className={classes.wrapper}>
        <Container className={classes.title}>
          <Typography
            component="h2"
            variant="h2"
            align="center"
            color="textPrimary"
          >
            {props.data.etsyListing.title}
          </Typography>
        </Container>
        <Paper className={classes.root} elevation={3}>
          <Img fluid={props.data.etsyListing.childrenEtsyListingImage[0].childrenFile[0].childImageSharp.fluid} title={props.data.etsyListing.title} />
        </Paper>
        <Container>
          <Typography>
            &pound;{props.data.etsyListing.price}
          </Typography>
        </Container>
        <Container className={classes.description}>
          <Typography
            component="p"
            align="left"
            color="textPrimary">
              {ReactHtmlParser(props.data.etsyListing.description.replace(/^((http[s]?|ftp):\/)?\/?([^:/\s]+)((\/\w+)*\/)([\w-.]+[^#?\s]+)(.*)?(#[\w-]+)?$/gm, '$1www.keyringplanet.com/$6').split('\n').map((text, i) => i > 0 ? `<br />${text}` : text).join(' '))}
            </Typography>
        </Container>
      </Container>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query etsyListing($listing_id: Int!) {
    etsyListing(listing_id: { eq: $listing_id }) {
      title
      description
      price
      childrenEtsyListingImage {
        childrenFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
